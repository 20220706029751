import React from "react";
import team from "../Assets/team.png";

const CareerHero = () => {
  return (
    <>
      <h1 className="w-full text-4xl text-center">
        Endless inspiration,meaningful work,
      </h1>
      <h1
        className="w-full text-4xl font-bold text-center"
        style={{ color: "#0D9488" }}
      >
        Talented Team
      </h1>
      <img className="w-1/2 m-10" src={team} alt="team" />
    </>
  );
};

export default CareerHero;
