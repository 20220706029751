import React from "react";
import cardImg from "../Assets/card.jpg";

const Card = () => {
  const heading = "Title";
  const text =
    "asdasdknaskl asasmndalsk asb das akjdb asmbd kjasnkldasklda aklsdnklasnd lkasndklas da sdasdfsdf sdfskdlnflsdk fsdkjfnsdlknflksdf asdlkfnsdlknfsdf afsdsf dsfs dfs sf aw fe ";

  return (
    <div
      className="max-w-md overflow-hidden rounded-lg shadow-md"
      style={{ backgroundColor: "#19315A" }}
    >
      <img src={cardImg} alt="Card" className="object-cover w-full h-48" />
      <div className="p-6">
        <h2
          className="text-2xl font-semibold text-center text-gray-800"
          style={{ color: "#0D9488 " }}
        >
          {heading}
        </h2>
        <p className="mt-2 text-white">{text}</p>
      </div>
    </div>
  );
};

export default Card;
