import React from "react";

const AiStudio = () => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col items-center justify-center w-full gap-5 px-10">
        <div className="flex flex-row items-center justify-center w-full">
          <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
          <h1
            className="w-full text-4xl text-center"
            style={{ color: "#0D9488 " }}
          >
            HONCHI.AI STUDIOS
          </h1>
          <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
        </div>
      </div>
    </div>
  );
};

export default AiStudio;
