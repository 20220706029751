import React from "react";
import logoImg from "../Assets/logo.jpg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="flex flex-row items-center justify-between w-full px-8 h-[10vh] bg-white text-black">
      <div className="flex flex-row items-center justify-start gap-2">
        <Link to="">
          <img className="w-32 h-15 cover" src={logoImg} alt="logo" />
        </Link>
        <h1 className="font-semibold cursor-pointer">AI Software</h1>
        <h1 className="font-semibold cursor-pointer">Industries</h1>
        <h1 className="font-semibold cursor-pointer">Customers</h1>
        <h1 className="font-semibold cursor-pointer">Resources</h1>
        <h1 className="font-semibold cursor-pointer">News</h1>
        <Link className="" to="/career">
          <h1 className="font-semibold cursor-pointer">Career</h1>
        </Link>
        {/* <h1 className="font-semibold cursor-pointer">Contacts</h1> */}
      </div>
      <div className="flex flex-row items-center justify-start gap-2">
        <button>Sign IN</button>
        <button className="bg-blue-500 rounded-lg">Sign Up</button>
      </div>
    </div>
  );
};

export default Navbar;
