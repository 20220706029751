import React from "react";
import heroImg from "../Assets/hero.png";
const Hero = () => {
  return (
    <div className=" h-[90vh] flex items-center justify-center">
      <div className="relative flex flex-row items-center justify-around w-3/4 h-3/4">
        <div className="flex flex-col items-center justify-center w-full h-full gap-3 text-white ">
          <h1 className="mr-auto text-4xl font-bold ">This Is</h1>
          <h1
            className="mr-auto text-4xl font-bold"
            style={{ color: "#0D9488" }}
          >
            Enterprise Ai.
          </h1>
          <div className="flex flex-col items-center justify-center mr-auto">
            <p className="mr-auto">
              Honchi AI delivers a comprehensive Enterprise Ai
            </p>
            <p>applicaion development platform and a large and</p>
            <p>growing family of turnkey enterprise AI applications </p>
          </div>
          <div
            className="w-3/4 mr-auto"
            style={{ border: "2px solid #0D9488" }}
          ></div>
          <button
            className="p-2 mr-auto rounded-lg"
            style={{ background: "#0D9488" }}
          >
            Get Started
          </button>

          <img
            className="absolute opacity-10"
            style={{ left: "-50%" }}
            src={heroImg}
            alt="hero"
          />
        </div>
        <div className="w-full">
          <img src={heroImg} alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
