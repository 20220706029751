import React from "react";

const CareerOpportunities = () => {
  return (
    <>
      <div className="flex flex-row items-center justify-center w-full">
        <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
        <h1
          className="w-full text-4xl font-bold text-center"
          style={{ color: "#0D9488 " }}
        >
          Career Opportunities
        </h1>
        <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
      </div>
      <div className="flex flex-row items-center justify-start w-full">
        <div className="flex flex-col items-center justify-start flex-1 gap-4">
          <h1 className="text-2xl font-bold underline">Current Openings</h1>
          <h1>Customer Service</h1>
          <h1>Customer Solutions</h1>
          <h1>Customer Support</h1>
          <h1>Data Science</h1>
          <h1>Engineering</h1>
          <h1>HR,Finance,Legal</h1>
          <h1>Marketing</h1>
          <h1>Product Design</h1>
          <h1>Product Management</h1>
          <h1>Sales</h1>
        </div>
        <div className="flex flex-col items-center justify-start flex-1 gap-2">
          <div
            className="flex flex-col items-center w-full p-2 rounded-lg "
            style={{ border: "2px solid #0D9488" }}
          >
            <h1 className="text-2xl font-bold" style={{ color: "#0D9488" }}>
              ETI Developer
            </h1>
            <p>HONCHI AI is looking for experienced ETI Developers</p>
            <p>You will be applying...</p>
          </div>
          <div
            className="flex flex-col items-center w-full p-2 rounded-lg "
            style={{ border: "2px solid #0D9488" }}
          >
            <h1 className="text-2xl font-bold" style={{ color: "#0D9488" }}>
              ML Engineer
            </h1>
            <p>HONCHI AI is looking for experienced ML Engineer</p>
            <p>You will be applying...</p>
          </div>
          <div
            className="flex flex-col items-center w-full p-2 rounded-lg "
            style={{ border: "2px solid #0D9488" }}
          >
            <h1 className="text-2xl font-bold" style={{ color: "#0D9488" }}>
              Mlops Engineer
            </h1>
            <p>HONCHI AI is looking for experienced Mlops Engineer</p>
            <p>You will be applying...</p>
          </div>
          <div
            className="flex flex-col items-center w-full p-2 rounded-lg "
            style={{ border: "2px solid #0D9488" }}
          >
            <h1 className="text-2xl font-bold" style={{ color: "#0D9488" }}>
              DevOps
            </h1>
            <p>HONCHI AI is looking for experienced DevOps</p>
            <p>You will be applying...</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerOpportunities;
