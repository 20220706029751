import React from "react";

import CareerHero from "../Components/CareerHero";
import CareerOpportunities from "../Components/CareerOpportunities";
import Copyright from "../Components/Copyright";

const Career = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full px-10 pt-5">
      <CareerHero />
      <CareerOpportunities />
      <Copyright />
    </div>
  );
};

export default Career;
