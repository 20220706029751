import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import Career from "./Pages/Career";
import Navbar from "./Components/Navbar";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/career" element={<Career />} />
      </Routes>
    </div>
  );
}

export default App;
