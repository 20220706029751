import React from "react";
import Hero from "../Components/Hero";
import Application from "../Components/Application";
import AiStudio from "../Components/AiStudio";

const Home = () => {
  return (
    <div className="pb-5 " style={{ backgroundColor: "#231F20" }}>
      <Hero />
      <Application />
      <AiStudio />
    </div>
  );
};

export default Home;
