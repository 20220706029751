import React from "react";
import Card from "./Card";
import heroImg from "../Assets/hero.png";

const Application = () => {
  return (
    <div className="relative flex items-center justify-center w-full">
      <div className="flex flex-col items-center justify-center w-full gap-5 px-10 ">
        <div className="flex flex-row items-center justify-center w-full">
          <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
          <h1
            className="w-full text-4xl text-center"
            style={{ color: "#0D9488 " }}
          >
            AI Applications
          </h1>
          <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
        </div>
        <div className="grid justify-center grid-cols-3 gap-4">
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />

          <img
            className="absolute opacity-10"
            style={{ zIndex: "99", left: "-10%" }}
            src={heroImg}
            alt="hero"
          />
        </div>
      </div>
    </div>
  );
};

export default Application;
