import React from "react";

const Copyright = () => {
  return (
    <div className="flex flex-col w-full my-20 ">
      <div className="w-full" style={{ border: "2px solid #0D9488" }}></div>
      <div className="flex flex-row items-center justify-between w-full">
        <h1>Copyright © 2023 HONCHI. All rights reserved.</h1>
        <h1>Terms of Use & Privacy Policy</h1>
      </div>
    </div>
  );
};

export default Copyright;
